import React from 'react';
import { Form, Input, Button, Switch, Radio } from 'antd';
import jwt from "jsonwebtoken";
import './App.css';
import { FormInstance } from 'antd/es/form/Form';


interface IState {
  output: string;
  loading: boolean;
  aeyonsMeetVersion: string;
}

class App extends React.Component<{}, IState> {
  private TOKEN_SECRET: string = "meet";
  formRef = React.createRef<FormInstance<any>>();

  constructor(props: any) {
    super(props);
    this.state = {
      output: "",
      loading: false,
      aeyonsMeetVersion: "old"
    }
  }
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.output);
  }

  onFinish = (values: any) => {

    this.setState({ loading: true });

    const tokenOptions: jwt.SignOptions = {
      audience: "jitsi",
      expiresIn: values.expiry + "h",
      algorithm: "HS256",
      subject: values.domain_name,
      issuer: "meet"
    };

    let sound_parameters =  "#config.disableAEC=false&config.disableAGC=false&config.disableAP=false&config.disableHPF=false&config.disableNS=false&config.stereo=true&config.opusMaxAverageBitrate=128000";

    // "audience": "meet",
    // "issuer": "meet",
    // "subject": "*",
    let form_obj = {
      "room": "*",
      "expiry": values.expiry,
      "context": {
        "user": {
          "name": values.name,
          "email": values.email,
          "affiliation": values.affiliation,
        },
        "features": {
          "recording": values.recording ? values.recording : false,
          "livestreaming": values.livestreaming ? values.livestreaming : false,
          "screen-sharing": values.screensharing ? values.screensharing : false,
          "chat": values.chat ? values.chat : false,

        }
      }
    };

    const token = jwt.sign(form_obj, this.TOKEN_SECRET, tokenOptions);
    const data = `https://${values.domain_name}/${values.room}?jwt=${token}${sound_parameters}`;

    const xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", (e) => {
      if (xhr.readyState === xhr.DONE) {
        this.setState({ loading: false });
        let response: any = "";
        if (xhr.status === 200) {
          response = JSON.parse(xhr.responseText);
        }
        if (response) {
          this.setState({ output: response.data.tiny_url });
        } else {
          this.setState({ output: `https://${values.domain_name}/${values.room}?jwt=${token}${sound_parameters}` });
        }
        const footer_div = document.querySelectorAll<HTMLElement>('.footer')[0];
        footer_div.style.display = 'block';
        document.getElementsByClassName("footer")[0] && document.getElementsByClassName("footer")[0].scrollIntoView({ behavior: 'smooth' });

      }
    });
    // xhr.open("GET", `https://firebase-link.aeyons.net/?url=${data}`);
    let body = {
      url: `${data}`,
      domain: `meeting.aeyons.net`
    }
    // xhr.open("GET", `https://firebase-link.aeyons.net/?url=${data}`);
    xhr.open("POST", `https://api.tinyurl.com/create`);
    xhr.setRequestHeader('accept', 'application/json')
    xhr.setRequestHeader('authorization', 'Bearer c0YaIhgEpO0ZqngJnJiFlnsxZNtBxXPUMceDed15Urdsixh48TN1OmV1EVVN')
    xhr.setRequestHeader('content-type', 'application/json')
    xhr.send(JSON.stringify(body));
  }


  render = () => {
    return (
      <div className="container">
        <div className="header">
          <img className="logo" src="https://images.squarespace-cdn.com/content/v1/596763e803596ea603e6846f/1501617691677-WOMYPCCDXBGXMJR2HVYQ/red+stacked+lighter+with+R.png?format=1500w" />
        </div>
        <div className="body">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Form.Item required name="domain_name" label="Domain Name" hasFeedback initialValue="video4.aeyons.net" rules={[{ required: true, message: 'Please input domain name!' }]}>
              <Input placeholder="video4.aeyons.net" />
            </Form.Item>
            <Form.Item required name="email" label="Email" hasFeedback rules={[{ required: true, type: "email", message: 'Please input your email address!' }]}>
              <Input placeholder="youremail@example.com" />
            </Form.Item>
            <Form.Item required name="name" label="Name" hasFeedback rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input placeholder="Full Name" id="email" />
            </Form.Item>
            <Form.Item required name="room" label="Room Name" hasFeedback rules={[{ required: true, message: 'Please input the room name!' }]}>
              <Input id="room_name" />
            </Form.Item>
            <Form.Item required name="expiry" label="Expiry (in hours)" hasFeedback rules={[{ required: true, message: 'Please enter the expiry duration of token!' }]}>
              <Input type="number" />
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'Please pick an Affiliation!' }]}
              className="text-end radio-group" name="affiliation" label="Affiliation">
              <Radio.Group>
                <Radio value="owner">Artist</Radio>
                <Radio value="member" style={{ marginRight: '0px' }}>Student</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item className="text-end" name="recording" label="Recording" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item className="text-end" name="livestreaming" label="Live Streaming" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item className="text-end" name="screensharing" label="Screen Sharing" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item className="text-end" name="chat" label="chat" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center', marginBottom: '0' }}>
              <Button type="primary" htmlType="submit" loading={this.state.loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="footer" style={{ display: 'none' }}>
          <div style={{ background: '#e5e2e2', marginBottom: '1rem', padding: '0.5rem' }}>
            {this.state.output}
          </div>
          <Button style={{ width: "100px" }} hidden={this.state.output.length ? false : true} type="primary" htmlType="submit" onClick={this.copyToClipboard}>
            Copy
          </Button>
          <Button style={{ width: "100px", marginLeft: "1rem" }} hidden={this.state.output.length ? false : true} type="primary">
            <a href={this.state.output} target="_blank" rel="noopener noreferrer">Go to link</a>
          </Button>
        </div>
      </div >
    );
  }
}

export default App;
